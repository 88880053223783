import { useEffect, useState } from 'react'
import { useAuthState } from 'context'
import { VotesCardBig } from 'components/votes/voteCardBig'
import ReactPaginate from 'react-paginate'
import { Loader } from 'components/loader/loader'
import axios from 'axios'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { scroll } from 'components/utils'
import { SimpleLayout } from 'components/layout/simpleLayout'
import Select from 'react-select'
import { TopSimple } from 'blocks'
import FooterBrdr from 'assets/images/svg/footer.svg'

export const Voiting = () => {
  const { token } = useAuthState()
  const { id } = useParams()
  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const history = useHistory()
  const location = useLocation()
  const [region, setRegion] = useState(null)
  const [regionOpt, setRegionOpt] = useState([])
  const [nominationFilter, setNominationFilter] = useState(null)
  const [levelFilter, setLevelFilter] = useState(null)

  const nominationFilterOptions = [
    { value: 0, label: 'Все номинации' },
    { value: 1, label: 'Лучшая федеральная инициатива' },
    { value: 2, label: 'Лучшая региональная инициатива' },
    { value: 3, label: 'Лучшая местная инициатива' },
  ]
  const levelFilterOptions = [
    {value: 0, label: 'Все направления'},
    { value: 1, label: 'Сохранение исторической памяти и патриотическое воспитание' },
    { value: 2, label: 'Социальное обслуживание, социальная поддержка и защита граждан' },
    { value: 3, label: 'Охрана здоровья граждан, пропаганда здорового образа жизни' },
    { value: 4, label: 'Поддержка семьи, материнства, отцовства и детства' },
    { value: 5, label: 'Поддержка проектов в области науки, образования, просвещения' },
    { value: 6, label: 'Поддержка проектов в области культуры и искусства' },
    { value: 7, label: 'Охрана окружающей среды и защита животных' },
    { value: 8, label: 'Воспитание детей и молодёжи' },
    { value: 9, label: 'Укрепление межнационального и межрелигиозного согласия' },
  ]

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/list/region`).then((data) => {
      const tempRegions = data.data.data
      tempRegions &&
        tempRegions.length > 0 &&
        tempRegions.unshift({ id: '0', name: 'Все регионы' })
      setRegionOpt(tempRegions)
    })
  }, [])

  useEffect(() => {
    scroll('#root')
  }, [])

  // useEffect(() => {
  // 	history.location.search === '' && history.push('?page=1');
  // });

  useEffect(() => {
    const getParam = location.search.replace('?page=', '')
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios
      .get(
        `${process.env.REACT_APP_BACKEND}/application?page=${getParam}${
          region ? `&region_id=${region}` : ''
        }${nominationFilter ? `&nomination_id=${nominationFilter}` : ''}${
          levelFilter ? `&organisation_level_id=${levelFilter}` : ''
        }`,
      )
      .then((data) => {
        setNews(data.data)
        setPage(getParam)
        setLoading(false)
      })
  }, [token, location, id, region, nominationFilter, levelFilter])

  const renderBlocks = () => {
    if (!loading && news.data && news.data.length > 0) {
      return news.data.map((newsItem, index) => {
        return <VotesCardBig key={newsItem.id} votes={newsItem} />
      })
    }
    if (!loading && (!news.data || news.data.length < 1)) {
      return (
        <div className="news__empty">
          <span>
            Идет процесс подачи заявок на конкурс. Заявки будут опубликованы на
            сайте после их рассмотрения.
          </span>
        </div>
      )
    }
  }

  const handlePageClick = ({ selected }) => {
    if (selected === 0 && !location.search) {
      selected = 1
    }
    setPage(selected + 1)
    setLoading(true)
    history.push(`?page=${selected + 1}`)
  }
  return (
    <SimpleLayout>
      <TopSimple
        titleText="Поданные заявки"
        descText="В данном разделе вы можете ознакомиться с заявками участников конкурса со всей страны, а с 5 декабря проголосовать за понравившиеся НКО, проекты или идеи."
      />
      <section className="news" style={{ marginTop: 0 }}>
      <div className="footer__border">
        <img
          src={FooterBrdr}
          className="footer__border_img"
          alt="Footer Border"
        />
      </div>
        <div className="container news__container">
          {loading ? (
            <Loader />
          ) :  (
                <div className="select_wrap">
                  <div className="select__item">
                    <label>Регион, населенный пункт</label>
                    <Select
                      options={regionOpt?.map((e) => {
                        return { value: e.id, label: e.name }
                      })}
                      onChange={(e) => {
                        history.push(`?page=1`)
                        setRegion(e.value)
                      }}
                      value={regionOpt
                        .filter((regionItem) => {
                          return regionItem.id === region
                        })
                        .map((e) => {
                          return { value: e.id, label: e.name }
                        })}
                      placeholder={'Выбрать из списка'}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: '1px solid #2173BA',
                          borderRadius: '2px',
                          cursor: 'pointer',
                          '&:hover': {
                            borderColor: '#2173BA',
                          },
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#2173BA',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: '#2173BA',
                          '&:hover': {
                            color: '#2173BA',
                          },
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: '#2173BA',
                        }),
                      }}
                    />
                  </div>
                  <div className="select__item">
                    <label>Номинация</label>
                    <Select
                      options={nominationFilterOptions.map((e) => {
                        return { value: e.value, label: e.label }
                      })}
                      onChange={(e) => {
                        history.push(`?page=1`)
                        setNominationFilter(e.value === 0 ? null : e.value)
                      }}
                      value={nominationFilterOptions.filter(
                        (nomination) => nomination.value === nominationFilter,
                      )}
                      placeholder={'Выбрать из списка'}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: '1px solid #2173BA',
                          borderRadius: '2px',
                          cursor: 'pointer',
                          '&:hover': {
                            borderColor: '#2173BA',
                          },
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#2173BA',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: '#2173BA',
                          '&:hover': {
                            color: '#2173BA',
                          },
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: '#2173BA',
                        }),
                      }}
                    />
                  </div>
                  <div className="select__item">
                    <label>Направление</label>
                    <Select
                      options={levelFilterOptions.map((e) => {
                        return { value: e.value, label: e.label }
                      })}
                      onChange={(e) => {
                        history.push(`?page=1`)
                        setLevelFilter(e.value === 0 ? null : e.value)
                      }}
                      value={levelFilterOptions.filter(
                        (level) => level.value === levelFilter,
                      )}
                      placeholder={'Выбрать из списка'}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: '1px solid #2173BA',
                          borderRadius: '2px',
                          cursor: 'pointer',
                          '&:hover': {
                            borderColor: '#2173BA',
                          },
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#2173BA',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: '#2173BA',
                          '&:hover': {
                            color: '#2173BA',
                          },
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: '#2173BA',
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="news__list">{renderBlocks()}</div>
          {news?.meta?.last_page > 1 && (
            <div className="pagination__wrap">
              <ReactPaginate
                breakLabel="..."
                pageRangeDisplayed={3}
                pageCount={news.meta.last_page}
                forcePage={page - 1}
                disableInitialCallback={true}
                onPageChange={handlePageClick}
                renderOnZeroPageCount={null}
                containerClassName={'pagination'}
              />
              <div
                className={
                  'pagination__link previous ' +
                  (Number(page) > 1 ? '' : 'disable')
                }
                onClick={() => {
                  if (Number(page) > 1) {
                    handlePageClick({ selected: Number(page) - 2 })
                  }
                }}
              >
                {'<'}
              </div>
              <div
                className={
                  'pagination__link next ' +
                  (news.meta.last_page > Number(page) ? '' : 'disable')
                }
                onClick={() => {
                  if (news.meta.last_page > Number(page)) {
                    handlePageClick({ selected: Number(page) })
                  }
                }}
              >
                {'>'}
              </div>
            </div>
          )}
        </div>
      </section>
    </SimpleLayout>
  )
}
