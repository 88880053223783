import Ico1 from 'assets/images/content/profit/ico1.svg'
import Ico2 from 'assets/images/content/profit/ico2.svg'
import Ico3 from 'assets/images/content/profit/ico3.svg'
import Ico4 from 'assets/images/content/profit/ico4.svg'
import Ico5 from 'assets/images/content/profit/ico5.svg'
import Ico6 from 'assets/images/content/profit/ico6.svg'
import Ico7 from 'assets/images/content/profit/ico7.svg'
// import Ico8 from 'assets/images/content/profit/ico8.svg'
import './Profit.scss'

export const Profit = () => {
  return (
    <section className="profit">
      <div className="container">
        <h2 className="profit__title title">Что получают победители?</h2>
        <div className="profit__wrap">
          <div className='profit__left'>
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico1} alt="" />
              <div className="profit__item_text">
                Бесплатную помощь экспертов в составлении заявки для участия в одном из федеральных конкурсов грантов;
              </div>
            </div>
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico2} alt="" />
              <div className="profit__item_text">
                В случае, если победитель Конкурса, получив консультационную поддержку, не выигрывает ни на одном из федеральных конкурсов грантов, то
                Партия поможет реализовать инициативу в соответствии с утвержденным Организационным комитетом форматом и размером поддержки;
              </div>
            </div>
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico4} alt="" />
              <div className="profit__item_text">
              Содействие в продвижении поданной на Конкурс инициативы в СМИ; 
              </div>
            </div>
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico5} alt="" />
              <div className="profit__item_text">
              PR-консультирование по продвижению поданной на Конкурс инициативы; 
              </div>
            </div>
          </div>
          <div className='profit__right'>
            
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico6} alt="" />
              <div className="profit__item_text">Наставников для реализации поданной на Конкурс инициативы из числа депутатов Государственной Думы Федерального Собрания Российской Федерации и сторонников Партии;</div>
            </div>
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico7} alt="" />
              <div className="profit__item_text">
              Возможность попробовать себя в политической деятельности, при желании для победителей Конкурса будет обеспечено сопровождение участия в предварительном голосовании «Единой России» для определения кандидатов в депутаты и закреплён федеральный наставник в рамках проекта Автономной некоммерческой организации дополнительного профессионального образования «Высшая партийная школа Всероссийской политической партии «ЕДИНАЯ РОССИЯ» «Политстарт»; 
              </div>
            </div>
            <div className="profit__item">
              <img className="profit__item_ico" src={Ico3} alt="" />
              <div className="profit__item_text">Участие во Всероссийском форуме гражданских инициатив.</div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  )
}
