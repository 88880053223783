import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { NavLink } from 'react-router-dom'
import IconLeftArrowWhite from 'assets/images/svg/icon_left-arrow-white.svg'
import './index.scss'
const Breadcrumbs = ({ breadcrumbs, title, nomination }) => {
  const nominationFilterOptions = {
    1: 'Лучшая федеральная инициатива',
    2: 'Лучшая региональная инициатива',
    3: 'Лучшая местная инициатива',
  }

  return (
    <div className="breadscrumb">
      <img src={IconLeftArrowWhite} alt={'ico'} />
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        return (
          <NavLink
            key={match.url}
            className={
              index + 1 !== breadcrumbs.length
                ? 'crumb_item crumb_first'
                : 'crumb_item'
            }
            to={match.url}
          >
            {breadcrumb.key === '/vote/sendvote' && nomination
              ? nominationFilterOptions[nomination]
              : breadcrumb}{' '}
          </NavLink>
        )
      })}
    </div>
  )
}

export default withBreadcrumbs([
  {
    path: '/',
    breadcrumb: 'Главная',
  },
  {
    path: '/login',
    breadcrumb: 'Авторизация',
  },
  {
    path: '/profile',
    breadcrumb: 'Подача заявки на конкурс',
  },
  {
    path: '/vote',
    breadcrumb: 'Подача заявки на конкурс',
  },
  {
    path: '/vote/sendvote',
    breadcrumb: 'Подача заявки на конкурс',
  },
])(Breadcrumbs)
