import Breadcrumbs from 'components/breadcrumbs'
import './TopSimple.scss'

export const TopSimple = (props) => {
  return (
    <section className="topsimple">
      <div className="topsimple__bg">
        <div className="container topsimple__container">
          <Breadcrumbs title={props.btitle} />
          {props.nomination && props.direction && (
            <div className='topsimple__nomination'><p>{props.nomination}</p><span>•</span><p>{props.direction}</p></div>
          )}
          {props.titleText && (
            <h1 className="topsimple__title">{props.titleText}</h1>
          )}
          {props.captionText && (
            <p className="topsimple__caption">{props.captionText}</p>
          )}
          {props.descText && (
            <p
              className={
                'topsimple__text ' + (props.descTextShort ? 'short' : '')
              }
            >
              {props.descText}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}
