import { useEffect } from 'react'
import { useAuthState } from 'context'
import { MainLayout } from 'components/layout/mainLayout'
import { Bckgr } from 'blocks'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Loader } from 'components/loader/loader'
import { updateUser } from 'context/actions'
import { useAuthDispatch, logout } from 'context'
import { scroll } from 'components/utils'
import BreadcrumbsProfile from 'components/breadcrumbsProfile'
import Dot from 'assets/images/content/vote/dot.svg'
import DotSc from 'assets/images/content/vote/dot-sc.svg'

export const Vote = () => {
  const { token } = useAuthState()
  const [loading, setLoading] = useState(true)
  const [votedItems, setVotedItems] = useState({ 1: false, 2: false, 3: false })
  const [canVoted, setCanVoted] = useState(true)
  const dispatch = useAuthDispatch()
  const history = useHistory()

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios
        .get(`${process.env.REACT_APP_BACKEND}/user/profile`)
        .then((data) => {
          updateUser(dispatch, data.data.data).then(() => {
            let app = data.data.data.applications
            let res = votedItems
            if (app.length > 0) {
              for (var i = 0; i < app.length; i++) {
                res[app[i].nomination_id] = true
              }
              setCanVoted(false)
            } else {
              setCanVoted(true)
            }

            setVotedItems(res)
            setLoading(false)
          })
        })
        .catch(function (err) {
          logout(dispatch)
        })
    } else {
      setLoading(false)
    }
  }, [token, dispatch, history, votedItems])
  useEffect(() => {
    scroll('#root')
  }, [])

  return (
    <MainLayout>
      <Bckgr />
      <section className="vote">
        <div className="container vote__container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <BreadcrumbsProfile />
              <h2 className="vote__title title">Подача заявки</h2>
              <div className="vote__descr">Выберите номинацию</div>
              <div className="vote__cards">
                <div className={'vote__card ' + (votedItems[1] ? 'voted' : '')}>
                  <img
                    className="vote__card_dot"
                    src={votedItems[1] ? DotSc : Dot}
                    alt=""
                  ></img>
                  <div className="vote__card_title">
                  Лучшая федеральная инициатива
                  </div>
                  <div className="vote__card_descr">
                  Определяются лучшие инициативы на федеральном уровне для реализации в 2025-2026г.
                  </div>
                  {votedItems[1] ? (
                    <div className="vote__card_voted">Заявка отправлена</div>
                  ) : canVoted ? (
                    <Link
                      to={{
                        pathname: '/vote/sendvote',
                        query: { nomination: 1, title: 'Лучшая НКО' },
                      }}
                    >
                      <button className="vote__card_btn button">выбрать</button>
                    </Link>
                  ) : (
                    <div className="vote__card_voted"></div>
                  )}
                </div>
                <div className={'vote__card ' + (votedItems[2] ? 'voted' : '')}>
                  <img
                    className="vote__card_dot"
                    src={votedItems[2] ? DotSc : Dot}
                    alt=""
                  ></img>
                  <div className="vote__card_title">
                  Лучшая региональная инициатива
                  </div>
                  <div className="vote__card_descr">
                  Определяются лучшие инициативы на региональном уровне для реализации в 2025-2026г.
                  </div>
                  {votedItems[2] ? (
                    <div className="vote__card_voted">Заявка отправлена</div>
                  ) : canVoted ? (
                    <Link
                      to={{
                        pathname: '/vote/sendvote',
                        query: { nomination: 2, title: 'Лучший проект' },
                      }}
                    >
                      <button className="vote__card_btn button">выбрать</button>
                    </Link>
                  ) : (
                    <div className="vote__card_voted"></div>
                  )}
                </div>
                <div className={'vote__card ' + (votedItems[3] ? 'voted' : '')}>
                  <img
                    className="vote__card_dot"
                    src={votedItems[3] ? DotSc : Dot}
                    alt=""
                  ></img>
                  <div className="vote__card_title">
                  Лучшая местная инициатива
                  
                  </div>
                  <div className="vote__card_descr" style={{marginBottom: '30px'}}>
                  Определяются лучшие инициативы на местном уровне для реализации в 2025-2026г.
                  </div>
                  {votedItems[3] ? (
                    <div className="vote__card_voted">Заявка отправлена</div>
                  ) : canVoted ? (
                    <Link
                      to={{
                        pathname: '/vote/sendvote',
                        query: { nomination: 3, title: 'Лучшая идея' },
                      }}
                    >
                      <button className="vote__card_btn button">выбрать</button>
                    </Link>
                  ) : (
                    <div className="vote__card_voted"></div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="vote__ext">
            Каждый пользователь может подать только 1 заявку на Конкурс, а в
            последствии голосовать один раз в каждой номинации.
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
