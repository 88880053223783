import { MainLayout } from 'components/layout/mainLayout'
import { MainVotes } from 'components/votes'
import {
  Top,
  SubTop,
  Nominations,
  Profit,
  Steps,
  // Comitet,
  Bottom,
} from 'blocks'
import React, { useEffect } from 'react'
import { scroll } from 'components/utils'

export const Main = () => {
  useEffect(() => {
    scroll('#root')
  }, [])
  return (
    <MainLayout>
      <Top />
      <SubTop />
      <Nominations />
      <Profit />
      <Steps />
      <MainVotes />
      {/* <Comitet /> */}
      <Bottom />
    </MainLayout>
  )
}
