import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'components/loader/loader'
import { SimpleLayout } from 'components/layout/simpleLayout'
import axios from 'axios'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { scroll } from 'components/utils'
import { useAuthState } from 'context'
// import { Link } from 'react-router-dom'
import { TopSimple } from 'blocks'
// import Like from 'assets/images/svg/like.svg'
import { SubmitModal } from 'components/modals/SubmitModal'
// import { useRef } from 'react'
import { SuccessModal } from 'components/modals/SuccessModal'
import Mock from 'assets/images/mock.png'
import { BestNko } from './cards'
import FooterBrdr from 'assets/images/svg/footer.svg'

export const DetailNews = () => {
  const { id } = useParams()

  const [detailNews, setDetailNews] = useState([])
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const { token, userDetails } = useAuthState()
  const [voteOpen, setVoteOpen] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)
  // const [votedItem, setVotedItem] = useState(false)
  const [hiddenApp, setHiddenApp] = useState(false)
  const { data } = detailNews

  useEffect(() => {
    // function updateVotedItems(app) {
    //   if (app?.length > 0 && detailNews?.data) {
    //     for (var i = 0; i < app.length; i++) {
    //       if (app[i].id === detailNews.data.id) {
    //         setVotedItem(true)
    //       }
    //     }
    //   }
    // }
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND}/application/${id}`)
      .then((data) => {
        setDetailNews(data.data)
        setVoteOpen(false)
        // updateVotedItems(userDetails?.votings)
        setLoading(false)
      })
      .catch(() => {
        setHiddenApp(true)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    scroll('#root')
  }, [])
  // const btnRef = useRef()


  return (
    <SimpleLayout>
      <TopSimple
        nomination={data?.nomination.description}
        direction={data?.organisation_level.description}
        titleText={data?.name ? data.name : ''}
        descText={data?.description ? data.description : ''}
        descTextShort={true}
        captionText={data?.region?.name ? data.region.name : ''}
        btitle={data?.name ? data.name : ''}
      />
      <section className="text-page">
      <div className="footer__border">
        <img
          src={FooterBrdr}
          className="footer__border_img"
          alt="Footer Border"
        />
      </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="container text-page__container">
            {hiddenApp ? (
              <div className="text-page__hidden">Заявка на рассмотрении</div>
            ) : (
              <>
                <div className="text-page__right_mobile">
                  <img
                    src={data?.image?.path ? data.image.path : Mock}
                    alt=""
                  />
                </div>

                <BestNko data={data} />
                {/* {true && (
                  <div className="text-page__likes">
                    <div className="text-page__likes_wrap">
                      <img src={Like} alt="" />
                      <span className="like_text">
                        {data?.count_votes ? data?.count_votes : 0}
                      </span>
                      {token && userDetails?.name && (
                        <button
                          onClick={() => setVoteOpen(true)}
                          className={`main__btn btn_vote button text-page__btn ${votedItem ? 'voted' : ''
                            }`}
                          type="button"
                          ref={btnRef}
                          disabled={votedItem}
                        >
                          {votedItem ? 'Вы проголосовали' : 'Поддержать'}
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {(!token || !userDetails?.name) && (
                  <div className="text-page__bottom">
                    <div className="text-page__noreg">
                      <span>
                        Чтобы принять участие в голосовании,
                        <br />
                        необходимо пройти процедуру верификации
                      </span>
                      <Link to="/login">
                        <button
                          className="text-page__noreg_btn button"
                          type="button"
                        >
                          Зарегистрироваться
                        </button>
                      </Link>
                    </div>
                  </div>
                )} */}
              </>
            )}
          </div>
        )}
      </section>
      <SubmitModal
        isOpen={voteOpen}
        setIsOpen={setVoteOpen}
        id={data?.id}
        token={token}
        setDetailNews={setDetailNews}
        setSuccessOpen={setSuccessOpen}
      />
      <SuccessModal
        isOpen={successOpen}
        setIsOpen={setSuccessOpen}
        votesCount={data?.count_votes}
      />
    </SimpleLayout>
  )
}
