export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timezone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const formatDate2 = (dateString) => {
  return new Date(dateString).toLocaleString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

export const scroll = (selector) => {
  const section = document.querySelector(`${selector}`)
  return section
    ? section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    : null
}

export const checkWindow = (maxWidthPixel) =>
  typeof window !== 'undefined' &&
  window.matchMedia(`(max-width: ${maxWidthPixel}px)`).matches

export const isMobile = checkWindow(1024)

export const isPhone = checkWindow(768)

export const rebuildData = (values) => {
  let formData = new FormData()
  if (values.file_calendar_plan)
    formData.append(`file_calendar_plan`, values.file_calendar_plan)
  if (values.geography_realisation_id)
    formData.append(`geography_realisation_id`, values.geography_realisation_id.value)
  if (values.is_individual)
    formData.append(`is_individual`, values.is_individual)
  if (!values.is_individual)
    formData.append(`is_individual`, false)
  if (values.fio)
    formData.append(`fio`, values.fio)
  if (values.target)
    formData.append(`target`, values.target)
  if (values.phone)
    formData.append(`phone`, values.phone)
  if (values.email)
    formData.append(`email`, values.email)
  if (values.idea_location)
    formData.append(`idea_location`, values.idea_location)
  if (values.date_start)
    formData.append(`date_start`, formatDate2(values.date_start))
  if (values.date_end)
    formData.append(`date_end`, formatDate2(values.date_end))
  if (values.quantity_quality_result)
    formData.append(`quantity_quality_result`, values.quantity_quality_result)

  if (values.company_short_name)
    formData.append(`company_short_name`, values.company_short_name)
  if (values.company_inn)
    formData.append(`company_inn`, values.company_inn)
  if (values.company_ogrn)
    formData.append(`company_ogrn`, values.company_ogrn)
  if (values.company_address_ur)
    formData.append(`company_address_ur`, values.company_address_ur)
  if (values.company_address_fact)
    formData.append(`company_address_fact`, values.company_address_fact)
  if (values.addrHidden.length > 0)
    values.addrHidden.map((e, i) => formData.append(`address[${i}]`, e))
  if (values.nomination_id)
    formData.append(`nomination_id`, values.nomination_id)
  if (values.idea_team)
    formData.append(`idea_team`, values.idea_team)
  if (values.idea_team_description)
    formData.append(`idea_team_description`, values.idea_team_description)
  if (values.is_individual)
    formData.append(`is_individual`, values.is_individual)
  if (values.organisation_level_id)
    formData.append(`organisation_level_id`, values.organisation_level_id.value)
  if (values.image_application)
    formData.append(`image_application`, values.image_application)
  if (values.company_name) formData.append(`company_name`, values.company_name)
  if (values.company_fio) formData.append(`company_fio`, values.company_fio)
  if (values.individual_position)
    formData.append(`individual_position`, values.individual_position)
  if (values.region_id?.length > 1)
    values.region_id.map((e, i) => formData.append(`region_id[${i}]`, e.value))
  if (values.region_id?.value)
    formData.append(`region_id[0]`, values.region_id.value)
  if (!values.region_id)
    formData.append(`region_id[0]`, [])
  if (values.name) formData.append(`name`, values.name)
  if (values.individual_name)
    formData.append(`individual_name`, values.individual_name)
  if (values.individual_phone)
    formData.append(`individual_phone`, values.individual_phone)
  if (values.individual_email)
    formData.append(`individual_email`, values.individual_email)
  if (values.description) formData.append(`description`, values.description)
  if (values.actual) formData.append(`actual`, values.actual)
  if (values.company_links_Site)
    formData.append(`company_links_Site`, values.company_links_Site)
  if (values.company_links_SnOK)
    formData.append(`company_links_SnOK`, values.company_links_SnOK)
  if (values.company_links_SnVK)
    formData.append(`company_links_SnVK`, values.company_links_SnVK)
  if (values.company_links_Telegram)
    formData.append(`company_links_Telegram`, values.company_links_Telegram)
  if (values.individual_links_Site)
    formData.append(`individual_links_Site`, values.individual_links_Site)
  if (values.other_links)
    formData.append(`other_links`, values.other_links)
  if (values.individual_links_SnOK)
    formData.append(`individual_links_SnOK`, values.individual_links_SnOK)
  if (values.individual_links_VK)
    formData.append(`individual_links_VK`, values.individual_links_VK)
  if (values.individual_links_Telegram)
    formData.append(
      `individual_links_Telegram`,
      values.individual_links_Telegram,
    )
  if (values.project_links_SMI)
    formData.append(`project_links_SMI`, values.project_links_SMI)
  if (values.project_links_SnOK)
    formData.append(`project_links_SnOK`, values.project_links_SnOK)
  if (values.project_links_SnVK)
    formData.append(`project_links_SnVK`, values.project_links_SnVK)
  if (values.project_links_Telegram)
    formData.append(`project_links_Telegram`, values.project_links_Telegram)
  if (values.achievements_1)
    formData.append(`achievements_1`, values.achievements_1)
  if (values.achievements_2)
    formData.append(`achievements_2`, values.achievements_2)
  if (values.achievements_3)
    formData.append(`achievements_3`, values.achievements_3)
  if (values.achievements_4)
    formData.append(`achievements_4`, values.achievements_4)
  if (values.achievements_5)
    formData.append(`achievements_5`, values.achievements_5)
  if (values.company_nko_count_peaple)
    formData.append(`company_nko_count_peaple`, values.company_nko_count_peaple)
  if (values.company_nko_peaple_achievements)
    formData.append(
      `company_nko_peaple_achievements`,
      values.company_nko_peaple_achievements,
    )
  if (values.company_nko_count_employee)
    formData.append(
      `company_nko_count_employee`,
      values.company_nko_count_employee,
    )
  if (values.company_nko_employee_achievements)
    formData.append(
      `company_nko_employee_achievements`,
      values.company_nko_employee_achievements,
    )
  if (values.company_nko_resource_profit)
    formData.append(
      `company_nko_resource_profit`,
      values.company_nko_resource_profit,
    )
  if (values.file_smeta) formData.append(`file_smeta`, values.file_smeta)
  if (values.company_address)
    formData.append(`company_address`, values.company_address)
  if (values.company_phone)
    formData.append(`company_phone`, values.company_phone)
  if (values.company_email)
    formData.append(`company_email`, values.company_email)
  if (values.company_created_date)
    formData.append(`company_created_date`, values.company_created_date)
  if (values.company_file_zero_debt)
    formData.append(`company_file_zero_debt`, values.company_file_zero_debt)
  if (values.company_file_appruve)
    formData.append(`company_file_appruve`, values.company_file_appruve)
  if (values.attachFiles.length > 0) {
    for (var i = 0; i < values.attachFiles.length; i++) {
      formData.append('files_additional[]', values.attachFiles[i])
    }
  }
  if (values.ustavFiles.length > 0) {
    for (var l = 0; l < values.ustavFiles.length; l++) {
      formData.append('company_file_ustavnie[]', values.ustavFiles[l])
    }
  }
  // if (values.linksProject.length > 0) {
  //   for (var j = 0; j < values.linksProject.length; j++) {
  //     formData.append('links_Project[]', values.linksProject[j])
  //   }
  // }

  return formData
}
