import './Steps.scss'

export const Steps = () => {
  return (
    <section id='steps' className="steps">
      <div className="container">
        <h2 className="steps__title title">Этапы реализации конкурса</h2>
        <div className="steps__wrap">
          <div className="steps__item">
            <div className="steps__item_text">
            До 23 декабря 2024 года <br></br>
            (включительно)
            </div>
            <div className="steps__item_desc">Прием заявок на сайте</div>
            <div className="steps__item_dot steps__item_dot_start"></div>
          </div>
          <div className="steps__item">
            <div className="steps__item_text">
            До 28 января 2025 года <br></br>
            (включительно)
            </div>
            <div className="steps__item_desc">
              Народное голосование,<br></br> определение полуфиналистов
            </div>
            <div className="steps__item_dot steps__item_dot_middle"></div>
          </div>
          <div className="steps__item">
            <div className="steps__item_text">
            Июнь 2025 года
            </div>
            <div className="steps__item_desc">
            Подведение итогов,<br />объявление победителей
            </div>
            <div className="steps__item_dot steps__item_dot_middle"></div>
          </div>
          <div className="steps__item">
            <div className="steps__item_text">
            Сентябрь 2025 года –<br></br>
            сентябрь 2026 года
            </div>
            <div className="steps__item_desc">
            Реализация инициатив
            </div>
            <div className="steps__item_dot steps__item_dot_stop"></div>
          </div>
        </div>
      </div>
    </section>
  )
}
