import Logo from 'assets/images/logo.svg'
import FooterBrdr from 'assets/images/svg/footer1.svg'
import VkSvg from 'assets/images/svg/icon_vk.svg'
import TgSvg from 'assets/images/svg/tg.svg'
import ArrowUp from 'assets/images/svg/icon_arrow-up.svg'
import { Link } from 'react-router-dom'
import { scroll } from 'components/utils'
import Polozhenie from 'assets/pdf/polozhenie-o-konkurse.pdf'
import './style.scss'

export const Footer = (props) => {
  return (
    <footer
      className={props.layoutType === 'simple' ? 'footer simple' : 'footer'}
    >
      <div className="footer__border">
        <img
          src={FooterBrdr}
          className="footer__border_img"
          alt="Footer Border"
        />
      </div>
      <div className="container footer__container">
        <Link className="logo_link" to={'/'}>
          <img src={Logo} alt={'logo'} />
        </Link>
        <p className="footer__text">
          Всероссийский конкурс поддержки гражданских инициатив
          <br />
          ©2024, Партия «Единая Россия».
          <br />
          <br />

          Все права защищены.{' '}
          <a
            href="https://er.ru/upages/privacy-policy"
            rel="noreferrer"
            target={'_blank'}
          >
            Политика конфиденциальности.&nbsp;
          </a>
           Поддержка пользователей
        </p>
        <p className="footer__desc">
          Продолжая находиться на данном сайте, вы соглашаетесь на
          предоставление информации об ip-адресе, имени и стране домена
          провайдера, переходах с одной страницы на другую и cookies.
        </p>
        <div className="footer__social-list">
          <a
            className="footer__social-item"
            href="https://vk.com/storonniki_er"
            target="_blank"
            rel="noreferrer"
          >
            <img src={VkSvg} className="footer__icon-vk" alt="VK" />
          </a>
          <a
            className="footer__social-item"
            href="https://t.me/storonnikiER"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TgSvg} className="footer__icon-vk" alt="Telegram" />
          </a>
        </div>
        <div className="footer__btns">
          <a
            className="footer__rules"
            href={Polozhenie}
            target="_blank"
            rel="noreferrer"
          >
            Положение о конкурсе
          </a>
          <button
            className="footer__btn js-scroll-to-top"
            type="button"
            onClick={() => scroll('#root')}
          >
            Наверх
            <img src={ArrowUp} className="footer__arrow-up" alt="icon" />
          </button>
        </div>
      </div>
    </footer>
  )
}
