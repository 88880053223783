import { useEffect, useState } from 'react'
import Logo from 'assets/images/logo.svg'
import Single from 'assets/images/svg/single.svg'
import { useHistory, Link } from 'react-router-dom'
import { useAuthState /*useAuthDispatch, logout*/ } from 'context'
// import { useHistory } from 'react-router-dom'
import './style.scss'
import { scroll } from 'components/utils'
import VkSvg from 'assets/images/svg/vk-dark.svg'
import TgSvg from 'assets/images/svg/tg-dark.svg'
import MMenu from 'assets/images/svg/mmenu.svg'
import Close from 'assets/images/svg/close.svg'

export const Header = (props) => {
  const { userDetails } = useAuthState()
  // const history = useHistory()
  // const dispatch = useAuthDispatch()
  const [mmenu, setMmenu] = useState(false)
  const history = useHistory()

  // const handleLogout = () => {
  //   logout(dispatch)
  //   history.push('/login')
  // }

  useEffect(() => {
    if (history?.location?.state?.scrollTo) {
      scroll(history?.location?.state?.scrollTo)
      delete history?.location?.state?.scrollTo
    }
  }, [history])

  return (
    <header id="header" className="header">
      <div className="container header__container">
        <div
          className={'main__menu_mobile ' + (mmenu ? 'active' : '')}
          onClick={() => {
            setMmenu(!mmenu)
          }}
        >
          {mmenu ? <img src={Close} alt="" /> : <img src={MMenu} alt="" />}
        </div>
        <Link
          className="logo_link"
          to={'/'}
          style={mmenu ? { opacity: '0.3' } : {}}
        >
          <img src={Logo} alt={'logo'} />
        </Link>
        <div className={'main__menu ' + (mmenu ? 'active' : '')}>
          <div
            className="main__menu_btn"
            onClick={() => {
              setMmenu(false)
              if (history.location.pathname === '/') {
                scroll('#subtop')
              } else {
                history.push({
                  pathname: '/',
                  state: { scrollTo: '#subtop' },
                })
              }
            }}
          >
            О конкурсе
          </div>
          <div
            className="main__menu_btn"
            onClick={() => {
              setMmenu(false)
              if (history.location.pathname === '/') {
                scroll('#comitet')
              } else {
                history.push({
                  pathname: '/',
                  state: { scrollTo: '#comitet' },
                })
              }
            }}
          >
            Оргкомитет
          </div>
          <div
            className="main__menu_btn"
            onClick={() => {
              setMmenu(false)
              if (history.location.pathname === '/') {
                scroll('#steps')
              } else {
                history.push({
                  pathname: '/',
                  state: { scrollTo: '#steps' },
                })
              }
            }}
          >
            Этапы проведения
          </div>
          <div
            className="main__menu_btn"
            onClick={() => {
              setMmenu(false)
              if (history.location.pathname === '/') {
                scroll('#nominations')
              } else {
                history.push({
                  pathname: '/',
                  state: { scrollTo: '#nominations' },
                })
              }
            }}
          >
            Номинации
          </div>
          <div
            className="main__menu_btn"
            onClick={() => {
              setMmenu(false)
              if (history.location.pathname === '/') {
                scroll('#votes')
              } else {
                history.push({
                  pathname: '/',
                  state: { scrollTo: '#votes' },
                })
              }
            }}
          >
          Поданные заявки
          </div>
          <div
            className="main__menu_btn"
            onClick={() => {
              setMmenu(false)
              if (history.location.pathname === '/') {
                scroll('#bottom')
              } else {
                history.push({
                  pathname: '/',
                  state: { scrollTo: '#bottom' },
                })
              }
            }}
          >
           Положение
          </div>
        </div>
        <div className="main__social-list">
          <a
            className="main__social-item"
            href="https://vk.com/storonniki_er"
            target="_blank"
            rel="noreferrer"
          >
            <img src={VkSvg} className="main__icon-vk" alt="VK" />
          </a>
          <a
            className="main__social-item"
            href="https://t.me/storonnikiER"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TgSvg} className="main__icon-vk" alt="Telegram" />
          </a>
        </div>
        {userDetails?.name ? (
          <Link
            className="main__btn-in"
            to={'/profile'}
            style={mmenu ? { opacity: '0.3' } : {}}
          >
            <img className="main__icon-person" src={Single} alt="person" />
            <span className="main__name-person">
              {userDetails?.surname} {userDetails?.name?.substring(0, 1)}.{' '}
              {userDetails?.patronymic?.substring(0, 1)}.
            </span>
          </Link>
        ) : (
          <Link
            className="main__btn-in"
            to={'/login'}
            style={mmenu ? { opacity: '0.3' } : {}}
          >
            <img className="main__icon-person" src={Single} alt="person" />
            <span className="main__name-person">Войти</span>
          </Link>
        )}
      </div>
    </header>
  )
}
