/*eslint no-unused-vars: ["error", { "destructuredArrayIgnorePattern": "^_" }]*/
import React from 'react'
import DatePicker from 'react-date-picker'
import { useField } from 'formik'
import { isMobile } from 'components/utils'
export const DateInput = (props) => {
  const [_field, _meta, helpers] = useField(props.field.name)
  return (
    <>
      <DatePicker
        {...props}
        name={props.name}
        value={props.value}
        showLeadingZeros={true}
        disableCalendar={isMobile}
        // maxDate={new Date()}
        className={props.className}
        wrapperClassName="datePicker"
        onChange={(value) => {
          helpers.setValue(value)
          props.onChange(value)
        }}
        options={props.options}
        onBlur={() => helpers.setTouched(true)}
      />
    </>
  )
}
