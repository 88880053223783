/* eslint-disable no-unused-vars */
/*eslint no-unused-vars: ["error", { "destructuredArrayIgnorePattern": "^_" }]*/
import React, { useState } from 'react'
import Select from 'react-select'
import { useField, Field } from 'formik'
import Autocomplete from 'react-autocomplete'
import axios from 'axios'



export const AutoCompleteField = (props) => {
  const [field, _meta, helpers] = useField(props.field.name)
  const [items, setItems] = useState([])
  // const [originalArr, setOriginalArr] = useState([])
  const [value, setValue] = useState('')

  const search = (event) => {
    const query = event.target.value
    setValue(query)
    if (event.target.value) {
      return axios
        .get(`${process.env.REACT_APP_BACKEND}/list/geoedinros?q=${query}`)
        .then((data) => setItems(data.data.suggestions))
    }
  }


  return (
    <div key={props.name} className="profile__input-block autocomplete">
      <label htmlFor={props.name}>{props.label}</label>

      <Autocomplete
        getItemValue={(item) => item.value}
        items={items}
        name={props.name}
        disabled={props.disabled}
        renderInput={function (inputProps) {
          return <input {...inputProps} disabled={props.disabled} />
        }}
        menuStyle={
          {
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            fontSize: '90%',
            position: 'absolute',
            top: '55px',
            left: '0',
            overflow: 'auto',
            zIndex: '9999',
            maxHeight: '500px'
          }
        }
        renderItem={(item, isHighlighted) =>
          <div key={item.value} style={{ background: isHighlighted ? 'lightgray' : 'white', zIndex: '9999', padding: '10px', fontSize: '16px' }}>
            {item.value}
          </div>
        }
        value={field.value}
        onChange={(e) => [helpers.setValue(e.target.value), search(e)]}
        onSelect={(val) => props.onSelect(val)}
      />
    </div>
  )
}

export const FormSelect = (props) => {
  const [field, _meta, helpers] = useField(props.field.name)

  return (
    <>
      <Select
        // menuIsOpen={true}
        ref={props.ref}
        name={props.name}
        value={field.value}
        isMulti={props.isMulti}
        className={props.className}
        placeholder={props.placeholder}

        onChange={(value) => {
          helpers.setValue(value)
          props.isMulti ? props.onChange(value) : props.onChange(value.value.toString())
        }}
        options={props.options}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={props.disabled}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            border: '1px solid #fff',
            padding: '6px 0px',
            borderRadius: '2px',
            cursor: 'pointer',
            '&:hover': {
              borderColor: '#fff',
            },
          }),
          singleValue: (base) => ({
            ...base,
            color: '#ffffff',
          }),
          placeholder: (base) => ({
            ...base,
            color: '#ffffff',
            opacity: '0.5',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: '#ffffff',
            '&:hover': {
              color: '#ffffff',
            },
          }),
          menu: (base) => ({
            ...base,
            marginTop: '0',
            borderRadius: '2px',
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: '200px',
            marginTop: '0',
          }),
          input: (base) => ({
            ...base,
            color: '#ffffff',
          }),
        }}
      />
    </>
  )
}

export const TextField = (props) => {
  const [field, _meta, helpers] = useField(props.name)
  return (
    <div key={props.name} className="profile__input-block">
      <label htmlFor={props.name}>{props.label}</label>
      <Field
        className={props.className}
        id={props.name}
        name={props.name}
        type="text"
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={field.value}
        onBlur={() => helpers.setTouched(true)}
      />
    </div>
  )
}

export const EssayField = (props) => {
  const [_field, _meta, helpers] = useField(props.name)
  return (
    <div className="profile__input-block">
      <label htmlFor={props.name}>Эссе заявки (файлы doc, pdf)</label>
      <label htmlFor={props.name} className={props.className}>
        <span className="profile__input-file-text">Прикрепить</span>
        <Field
          id={props.name}
          name={props.name}
          type="file"
          hidden
          value={''}
          accept=".doc,.docx,.pdf"
          placeholder="Ссылка"
          onChange={props.onChange}
          // value={field.value}
          onBlur={() => helpers.setTouched(true)}
        />
      </label>
      {props.value?.name && (
        <div className="images_wrap">
          <div
            key={props.value.name}
            className="profile__error"
            style={{
              color: 'black',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{props.value.name}</span>
            <span
              style={{
                color: 'red',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={props.onclick}
            >
              X
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export const SelectField = (props) => {
  return (
    <div
      key={props.name}
      className="profile__input-block"
      data-select2-id="select2-data-24-t573"
    >
      <label htmlFor={props.name}>{props.label}</label>
      <Field
        name={props.name}
        className={props.className}
        component={FormSelect}
        placeholder="Выберите из списка"
        value={props.values}
        blur={props.handleBlur}
        onChange={props.onChange}
        options={props.options.map((e, index) => {
          return {
            value: index,
            label: e,
          }
        })}
      />
      {/* <span className='authorization__error'>
				{errors.nomination && touched.nomination && errors.nomination}
			</span> */}
    </div>
  )
}

export const ImagesField = (props) => {
  return (
    <div className="profile__input-block">
      <label htmlFor="images">Фотографии (формат jpg, png)</label>
      <div className="profile__input-block-wrap">
        <label
          className="profile__input-file"
          htmlFor={props.name}
          style={{ width: '100%' }}
        >
          <span className="profile__input-file-text">Прикрепить</span>
          <Field
            className={props.className}
            id={props.name}
            hidden
            accept={'.jpg,.png,.pdf'}
            name={props.name}
            type={'file'}
            value={''}
            placeholder="Ссылка"
            onChange={props.onchange}
          />
          {/* <span className='authorization__error'>
						{errors.images && errors.images}
					</span> */}
        </label>
        {props.value && props.value.length > 0 && (
          <div className="images_wrap">
            {props.value.map((e, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="profile__error"
                    style={{
                      color: 'black',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{e.name}</span>
                    <span
                      style={{
                        color: 'red',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => props.onclick(e.name)}
                    >
                      X
                    </span>
                  </div>
                </>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
