/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { FormSelect, AutoCompleteField } from 'components/inputs/selectField'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'
import { useAuthState } from 'context'
import { DateInput } from 'components/inputs/birthdayInput'
import { scroll } from 'components/utils'
import { ErrorModal } from 'components/modals/ErrorModal'
// import {
//   validateNomination1,
//   validateNomination2,
//   validateNomination3,
// } from '../validation'
import { useHistory } from 'react-router-dom'
import { rebuildData } from 'components/utils'
import { ToastContainer } from 'react-toastify';
import { errorRegisterToast } from '../../notifications';
import { Loader } from '../../loader/loader'
import Pixel from 'assets/images/px.png'
import Loadimage from 'assets/images/content/sendvote/loadimage.svg'
import Attach from 'assets/images/content/sendvote/attach.svg'
import Cross from 'assets/images/content/sendvote/cross.svg'
// import AcceptFile from 'assets/pdf/podtverzhdenie-podachi-zayavki.docx'
// import Order from 'assets/pdf/order.docx'
import Smeta from 'assets/pdf/smeta.docx'
import { InputPhone } from 'components/inputs/phoneInput'
import { validateNomination1, validateNomination2 } from '../validation'

export const Nomination = (params) => {
  const { userDetails, token } = useAuthState()
  const [selectedRegionOption, setSelectedRegionOption] = useState(
    userDetails?.district?.region?.id || null,
  )
  const [selectedGeoOption, setSelectedGeoOption] = useState(null)
  const [selectedDirectionOption, setSelectedDirectionOption] = useState(null)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [addreses, setAddreses] = useState([])
  const [attachFiles, setAttachFiles] = useState([])
  const [ustavFiles, setUstavFiles] = useState([])
  const [errorSend, setErrorSend] = useState({})
  const [successSend, setSuccessSend] = useState(false)

  const directionOptions = [
    { value: 1, label: 'Сохранение исторической памяти и патриотическое воспитание' },
    { value: 2, label: 'Социальное обслуживание, социальная поддержка и защита граждан' },
    { value: 3, label: 'Охрана здоровья граждан, пропаганда здорового образа жизни' },
    { value: 4, label: 'Поддержка семьи, материнства, отцовства и детства' },
    { value: 5, label: 'Поддержка проектов в области науки, образования, просвещения' },
    { value: 6, label: 'Поддержка проектов в области культуры и искусства' },
    { value: 7, label: 'Охрана окружающей среды и защита животных' },
    { value: 8, label: 'Воспитание детей и молодёжи' },
    { value: 9, label: 'Укрепление межнационального и межрелигиозного согласия' },
  ]

  const geographyOptions = [
    [{ value: 1, label: 'Вся Россия' }, { value: 2, label: 'Несколько регионов' }],
    [{ value: 3, label: 'Регион' }, { value: 4, label: 'Населенный пункт, муниципальное образование, село и пр.' }],
    [{ value: 5, label: 'Населенный пункт, муниципальное образование, село и пр.' }]
  ]


  //   { value: 1, label: 'Вся Россия' },
  //   { value: 2, label: 'Регион' },
  //   { value: 3, label: 'Город' },
  //   { value: 4, label: 'Населенный пункт' },
  //   { value: 5, label: 'Муниципальное образование' },
  // ]


  const nominationFilterOptions = [
    { value: 1, label: 'Лучшая федеральная инициатива' },
    { value: 2, label: 'Лучшая региональная инициатива' },
    { value: 3, label: 'Лучшая местная инициатива' },
  ]

  // const ideaOptions = [
  //   { value: 1, label: 'руководитель проекта' },
  //   { value: 2, label: 'менеджер по работе с участниками' },
  //   { value: 3, label: 'pr-менеджер' },
  //   { value: 4, label: 'исполнительный продюсер' },
  //   { value: 5, label: 'технический директор' },
  // ]

  useEffect(() => {
    if (!userDetails.name) {
      history.push('/login')
    }
    if (!params.nomination) {
      history.push('/vote')
    }
    if (userDetails?.applications?.length > 0) {
      userDetails.applications.map((item) => {
        if (item.nomination_id === params.nomination) {
          history.push('/vote')
        }
        return null
      })
    }
  }, [params, history, userDetails])

  useEffect(() => {
    scroll('#root')
  }, [])

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }
  function addAttachFiles(filesObj) {
    let res = []
    if (attachFiles.length > 0) {
      res = attachFiles
    }
    if (filesObj.length > 0) {
      Object.keys(filesObj).forEach(function (key, index) {
        if (
          filesObj[key].size /
          1000 >
          5000
        ) {
          return errorRegisterToast(
            'Прикрепите файл размером меньше 5мб'
          );
        }
        res.push(filesObj[key])
      })
    }
    setAttachFiles([...res])
  }
  function deleteAttachFile(name) {
    let res = []
    if (attachFiles.length > 0) {
      attachFiles.forEach((file) => {
        if (file.name !== name) {
          res.push(file)
        }
      })
    }
    setAttachFiles([...res])
  }

  function addLinksMulti(link, callback) {
    let res = []
    let double = false
    if (addreses.length > 0 && link) {
      addreses.forEach((lnk) => {
        if (lnk === link) {
          double = true
        }
      })
    }
    if (double) {
      res = addreses
      setAddreses([...res])
      callback(res.length)
    } else {
      res = addreses
      if (link) {
        res.push(link)
      }
      setAddreses([...res])
      callback(res.length)
    }
  }


  function deleteAddr(name, calback) {
    let res = []
    if (addreses.length > 0) {
      addreses.forEach((file) => {
        if (file !== name) {
          res.push(file)
        }
      })
    }
    setAddreses([...res])
    calback('addrHidden', [...res])
  }


  function addUstavFiles(filesObj, callback) {
    let res = []
    if (ustavFiles.length > 0) {
      res = ustavFiles
    }
    if (filesObj.length > 0) {
      Object.keys(filesObj).forEach(function (key, index) {
        if (
          filesObj[key].size /
          1000 >
          5000
        ) {
          return errorRegisterToast(
            'Прикрепите файл размером меньше 5мб'
          );
        }
        res.push(filesObj[key])
      })
    }
    setUstavFiles([...res])
    callback(res.length)
  }
  function deleteUstavFile(name) {
    let res = []
    if (ustavFiles.length > 0) {
      ustavFiles.forEach((file) => {
        if (file.name !== name) {
          res.push(file)
        }
      })
    }
    setUstavFiles([...res])
  }
  // function addLinksMulti(link, calback) {
  //   let res = []
  //   let double = false
  //   if (linksProject.length > 0 && link) {
  //     linksProject.forEach((lnk) => {
  //       if (lnk === link) {
  //         double = true
  //       }
  //     })
  //   }
  //   if (double) {
  //     res = linksProject
  //     setLinksProject([...res])
  //     calback(res.length)
  //   } else {
  //     res = linksProject
  //     if (link) {
  //       res.push(link)
  //     }
  //     setLinksProject([...res])
  //     calback(res.length)
  //   }
  // }


  return (
    <>
      <Formik
        initialValues={{
          nomination_id: params?.nomination,
          is_individual: true,
          organisation_level_id: '',
          geography_realisation_id: '',
          name: '',
          fio: '',
          phone: '',
          addr: '',
          addrHidden: [],
          email: '',
          idea_team: '',
          idea_team_description: '',
          idea_location: '',
          company_name: '',
          company_fio: '',
          individual_position: '',
          region_id: '',
          date_start: '',
          date_end: '',
          individual_name: '',
          individual_phone: '',
          individual_email: '',
          quantity_quality_result: '',
          description: '',
          actual: '',
          target: '',
          image_application: '',
          file_calendar_plan: '',
          company_short_name: '',
          company_inn: '',
          company_ogrn: '',
          company_address_ur: '',
          company_address_fact: '',
          company_links_Site: '',
          company_links_SnOK: '',
          company_links_SnVK: '',
          company_links_Telegram: '',

          individual_links_Site: '',
          individual_links_SnOK: '',
          individual_links_VK: '',
          individual_links_Telegram: '',

          project_links_SMI: '',
          project_links_SnOK: '',
          project_links_SnVK: '',
          project_links_Telegram: '',

          achievements_1: '',
          achievements_2: '',
          achievements_3: '',
          achievements_4: '',
          achievements_5: '',

          company_nko_count_peaple: '',
          company_nko_peaple_achievements: '',
          company_nko_count_employee: '',
          company_nko_employee_achievements: '',

          company_nko_resource_profit: '',

          file_smeta: '',
          file_smeta_prev: '',
          company_address: '',
          company_phone: '',
          company_email: '',
          company_file_ustavnie_multi: '',
          company_file_ustavnie_hidden: ustavFiles.length,
          company_created_date: '',
          selected_date: '',
          company_file_zero_debt: '',
          company_file_zero_debt_prev: '',
          company_file_appruve: '',
          company_file_appruve_prev: '',
          files_multi: '',
          links_multi: '',
          links_hidden: '',
          personal_data_confirmation: false,
          agree: false,
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={(values) => values.is_individual === true ? validateNomination1(values) : validateNomination2(values)
        }
        onSubmit={({ ...values }) => {
          setIsLoading(true)
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          }
          values.company_phone = values.company_phone
            ? values.company_phone.replaceAll(/[+()\-\s]/g, '').substring(1)
            : ''
          values.individual_phone = values.individual_phone
            ? values.individual_phone.replaceAll(/[+()\-\s]/g, '').substring(1)
            : ''
          const data = rebuildData({
            ...values,
            ustavFiles: ustavFiles,
            attachFiles: attachFiles,
            addrHidden: addreses
          })
          axios
            .post(`${process.env.REACT_APP_BACKEND}/user/application`, data, {
              headers: headers,
            })
            .then((data) => {
              setSuccessSend(true)
            })
            .catch((e) =>
              setErrorSend(
                e?.response?.data?.errors
                  ? e.response.data.errors
                  : { error: ['Что то пошло не так...'] },
              ),
            )
            .finally(() => setIsLoading(false))
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleBlur }) => {
          return (
            <>
              <h2 className="sendvote__title title">
                {successSend
                  ? 'Ваш заявка принята'
                  : nominationFilterOptions.filter((item) => {
                    return (
                      params?.nomination && item.value === params?.nomination
                    )
                  })[0]?.label || 'Подача заявки'}
              </h2>

              {successSend ? (
                <>
                  <hr />
                  <div className="sendvote__simpletext">
                    По завершению модерации заявка будет отображена на сайте
                  </div>
                  <div className="sendvote__input-button to-right">
                    <button
                      className="sendvote__btn-submit button"
                      onClick={() => {
                        history.push('/')
                      }}
                    >
                      <span>Закрыть</span>
                    </button>
                  </div>
                </>
              ) : (
                <Form
                  onKeyDown={onKeyDown}
                  className="sendvote__form js-registration-form"
                >
                  {/* Направление */}
                  <>
                    <div className="sendvote__descr">Направление</div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="organisation_level_id">Направление*</label>
                        <Field
                          name="organisation_level_id"
                          id="organisation_level_id"
                          className={
                            errors.organisation_level_id
                              && touched.organisation_level_id

                              ? 'select error'
                              : 'select'
                          }
                          component={FormSelect}
                          placeholder="Направление"
                          value={selectedDirectionOption}
                          onChange={(e) => {
                            setSelectedDirectionOption(e)
                          }}
                          options={directionOptions}
                        />
                        <span className="sendvote__error">
                          {errors.organisation_level_id
                            &&
                            touched.organisation_level_id
                            &&
                            errors.organisation_level_id
                          }
                        </span>
                      </div>
                    </div>
                    <hr />
                  </>

                  <>
                    {/* Инициатор проекта */}
                    <div className="sendvote__descr">Инициатор инициативы</div>
                    <div className="sendvote__input-group double checkboxes">
                      <div className="sendvote__checkbox-block">
                        <label>
                          <Field
                            name="is_individual"
                            type="checkbox"
                            checked={values.is_individual === true}
                            onChange={() => {
                              setFieldValue('is_individual', true)
                              setFieldValue('individual_position', '')
                              setFieldValue('company_links_Site', '')
                              setFieldValue('company_links_SnOK', '')
                              setFieldValue('company_links_SnVK', '')
                              setFieldValue('company_links_Telegram', '')
                              setFieldValue('company_name', '')
                              setFieldValue('company_address', '')
                              setFieldValue('company_created_date', '')
                              setFieldValue('company_file_zero_debt', '')
                              setFieldValue('company_file_appruve', '')
                              setUstavFiles([])
                            }}
                          />
                          <span>Физическое лицо</span>
                        </label>
                      </div>
                      <div className="sendvote__checkbox-block">
                        <label>
                          <Field
                            name="is_individual"
                            type="checkbox"
                            checked={values.is_individual === false}
                            onChange={() => {
                              setFieldValue('is_individual', false)
                            }}
                          />
                          <span>Юридическое лицо</span>
                        </label>
                      </div>
                      <span className="sendvote__error">
                        {errors.is_individual &&
                          touched.is_individual &&
                          errors.is_individual}
                      </span>
                    </div>

                    <hr />
                  </>

                  {/* Контактные данные заявителя */}
                  <div className="sendvote__descr">
                    Контактные данные заявителя
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="fio">ФИО заявителя*</label>
                      <Field
                        className={
                          errors.fio && touched.fio
                            ? 'input error'
                            : 'input'
                        }
                        id="fio"
                        name="fio"
                        type="text"
                        placeholder="ФИО заявителя"
                        onChange={handleChange}
                        value={values.fio}
                      />
                      <span className="sendvote__error">
                        {errors.fio &&
                          touched.fio &&
                          errors.fio}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group double">
                    <div className="sendvote__input-block">
                      <label htmlFor="phone">
                        <br />
                        Контактный номер заявителя
                      </label>
                      <InputPhone
                        className={errors.phone ? 'input error' : 'input'}
                        onChange={(e) => {
                          if (e.target.value[4] !== '9')
                            return setFieldValue('phone', true)
                          return setFieldValue('phone', e.target.value)
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                      <span className="authorization__error">
                        {errors.phone && touched.phone && errors.phone}
                      </span>
                    </div>
                    <div className="sendvote__input-block">
                      <label htmlFor="email">
                        Адрес электронной почты для направления сообщений заявителю
                      </label>
                      <Field
                        className={
                          errors.email &&
                            touched.email
                            ? 'input error'
                            : 'input'
                        }
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <span className="sendvote__error">
                        {errors.email &&
                          touched.email &&
                          errors.email}
                      </span>
                    </div>
                  </div>
                  {values.is_individual === true && (
                    <>
                      <div className="sendvote__subdescr">
                        Ссылки на сайт и социальные сети заявителя
                      </div>
                      <div className="sendvote__input-group double">
                        <div className="sendvote__input-block">
                          <label htmlFor="individual_links_Site">
                            Ссылка на cайт**
                          </label>
                          <Field
                            className={
                              errors.individual_links_Site &&
                                touched.individual_links_Site
                                ? 'input error'
                                : 'input'
                            }
                            id="individual_links_Site"
                            name="individual_links_Site"
                            type="text"
                            placeholder="Ссылка на cайт"
                            onChange={handleChange}
                            value={values.individual_links_Site}
                          />
                          <span className="sendvote__error">
                            {errors.individual_links_Site &&
                              touched.individual_links_Site &&
                              errors.individual_links_Site}
                          </span>
                        </div>
                        <div className="sendvote__input-block">
                          <label htmlFor="individual_links_SnOK">
                            Ссылка на Одноклассники**
                          </label>
                          <Field
                            className={
                              errors.individual_links_SnOK &&
                                touched.individual_links_SnOK
                                ? 'input error'
                                : 'input'
                            }
                            id="individual_links_SnOK"
                            name="individual_links_SnOK"
                            type="text"
                            placeholder="Ссылка на Одноклассники"
                            onChange={handleChange}
                            value={values.individual_links_SnOK}
                          />
                          <span className="sendvote__error">
                            {errors.individual_links_SnOK &&
                              touched.individual_links_SnOK &&
                              errors.individual_links_SnOK}
                          </span>
                        </div>
                      </div>
                      <div className="sendvote__input-group double">
                        <div className="sendvote__input-block">
                          <label htmlFor="individual_links_VK">
                            Ссылка на VK**
                          </label>
                          <Field
                            className={
                              errors.individual_links_VK &&
                                touched.individual_links_VK
                                ? 'input error'
                                : 'input'
                            }
                            id="individual_links_VK"
                            name="individual_links_VK"
                            type="text"
                            placeholder="Ссылка на VK"
                            onChange={handleChange}
                            value={values.individual_links_VK}
                          />
                          <span className="sendvote__error">
                            {errors.individual_links_VK &&
                              touched.individual_links_VK &&
                              errors.individual_links_VK}
                          </span>
                        </div>
                        <div className="sendvote__input-block">
                          <label htmlFor="individual_links_Telegram">
                            Ссылка на Телеграм**
                          </label>
                          <Field
                            className={
                              errors.individual_links_Telegram &&
                                touched.individual_links_Telegram
                                ? 'input error'
                                : 'input'
                            }
                            id="individual_links_Telegram"
                            name="individual_links_Telegram"
                            type="text"
                            placeholder="Ссылка на Телеграм"
                            onChange={handleChange}
                            value={values.individual_links_Telegram}
                          />
                          <span className="sendvote__error">
                            {errors.individual_links_Telegram &&
                              touched.individual_links_Telegram &&
                              errors.individual_links_Telegram}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="idea_team_description">
                        Команда
                      </label>
                      <Field
                        id="idea_team_description"
                        name="idea_team_description"
                        type="textarea"
                        component="textarea"
                        placeholder={'Команда инициативы'}
                        onChange={handleChange}
                        value={values.idea_team_description}
                      />
                      <span className="sendvote__error">
                        {errors.idea_team_description &&
                          touched.idea_team_description &&
                          errors.idea_team_description}
                      </span>
                    </div>
                  </div>
                  <hr />

                  {/* Информация об проекте */}
                  <div className="sendvote__descr">
                    Информация об инициативе
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="geography_realisation_id">География реализации инициативы*</label>
                      <Field
                        name="geography_realisation_id"
                        id="geography_realisation_id"
                        className={
                          errors.geography_realisation_id && touched.geography_realisation_id
                            ? 'select error'
                            : 'select'
                        }
                        component={FormSelect}
                        placeholder="География реализации инициативы"
                        value={selectedGeoOption}
                        onChange={(e) => {
                          setFieldValue('region_id', '')
                          setSelectedGeoOption(e)
                        }}
                        options={geographyOptions[params.nomination - 1]}
                      />
                      <span className="sendvote__error">
                        {errors.geography_realisation_id &&
                          touched.geography_realisation_id &&
                          errors.geography_realisation_id}
                      </span>
                    </div>
                  </div>
                  {selectedGeoOption != 1 && (<div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="region_id">Регион*</label>
                      <Field
                        name="region_id"
                        id="region_id"
                        className={
                          errors.region_id && touched.region_id
                            ? 'select error'
                            : 'select'
                        }
                        component={FormSelect}
                        placeholder="Регион"
                        disabled={!values.geography_realisation_id}
                        isMulti={selectedGeoOption == 2}
                        value={selectedRegionOption}
                        onChange={(e) => {
                          setSelectedRegionOption(e)
                        }}
                        options={params?.region?.data?.map((e) => {
                          return { value: e.id, label: e.name }
                        })}
                      />
                      <span className="sendvote__error">
                        {errors.region_id &&
                          touched.region_id &&
                          errors.region_id}
                      </span>
                    </div>
                  </div>)}
                  {params.nomination !== 1 && selectedGeoOption && selectedGeoOption !== '3' ? (
                    <>
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="addr">
                            Муниципальное образование, село, пр.
                          </label>
                          <Field
                            disabled={!values.region_id}
                            className={
                              !!errors.addr
                                ? 'input datepicker error'
                                : 'input datepicker'
                            }
                            id="addr"
                            name="addr"
                            component={AutoCompleteField}
                            // onChange={setDateStart}
                            // value={dateStart}
                            onSelect={(e) => addLinksMulti(e, (len) => {
                              setFieldValue('addr', '')
                              setFieldValue('addrHidden', addreses)
                            })}
                            value={values.addr}
                          />
                          <span className="authorization__error">
                            {errors.addr &&
                              touched.addr &&
                              errors.addr}
                          </span>
                          {addreses.length > 0 && (
                            <div className="addres-text">
                              {addreses.map((addr, index) => (
                                <div
                                  key={index}
                                  className="addres-text__item"
                                >
                                  <div className="addres-text__item_text">
                                    {addr}
                                  </div>
                                  <img
                                    className="file-text__multiply_image"
                                    src={Cross}
                                    alt=""
                                    onClick={() => {
                                      deleteAddr(addr, setFieldValue)
                                    }}
                                  ></img>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="image_application">
                        Фотография / обложка карточки (будет отображаться при
                        голосовании)*
                      </label>
                      <div className="sendvote__imageprev">
                        <img src={imagePreview || Pixel} alt=""></img>
                        {!imagePreview && (
                          <div className="sendvote__loadimg">
                            <img src={Loadimage} alt=""></img>
                          </div>
                        )}
                        <input
                          className={
                            errors.image_application &&
                              touched.image_application
                              ? 'image error'
                              : 'image'
                          }
                          id="image_application"
                          name="image_application"
                          // accept="image/png,image/jpeg,image/jpg"
                          type="file"
                          onChange={(e) => {
                            if (
                              e.target.files[0].size /
                              1000 >
                              5000
                            ) {
                              return errorRegisterToast(
                                'Прикрепите файл размером меньше 5мб'
                              );
                            }
                            setFieldValue(
                              'image_application',
                              e.target.files[0],
                            )
                            const fileReader = new FileReader()
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                setImagePreview(fileReader.result)
                              }
                            }
                            fileReader.readAsDataURL(e.target.files[0])
                          }}
                        />
                      </div>
                      <span className="sendvote__error">
                        {errors.image_application &&
                          touched.image_application &&
                          errors.image_application}
                      </span>
                    </div>
                  </div>

                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="name">
                        Название инициативы
                      </label>
                      <Field
                        className={
                          errors.name && touched.name
                            ? 'input error'
                            : 'input'
                        }
                        id="name"
                        name="name"
                        type="text"
                        placeholder={'Название инициативы'}
                        onChange={handleChange}
                        value={values.name}
                      />
                      <span className="sendvote__error">
                        {errors.name && touched.name && errors.name}
                      </span>
                    </div>
                  </div>



                  <>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="date_start">
                          Срок реализации инициативы
                        </label>
                        <Field
                          className={
                            !!errors.date_start
                              ? 'input datepicker error'
                              : 'input datepicker'
                          }
                          id="date_start"
                          component={DateInput}
                          clearIcon={null}
                          calendarIcon={null}
                          monthPlaceholder={'ММ'}
                          dayPlaceholder={'ДД'}
                          yearPlaceholder={'ГГГГ'}
                          onChange={setDateStart}
                          value={dateStart}
                          name="date_start"
                          type="text"
                          placeholder="ДД.ММ.ГГГГ"
                        />
                        <span className="authorization__error">
                          {errors.date_start &&
                            touched.date_start &&
                            errors.date_start}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="date_end">
                          Дата завершения реализации
                        </label>
                        <Field
                          className={
                            !!errors.date_end
                              ? 'input datepicker error'
                              : 'input datepicker'
                          }
                          id="date_end"
                          component={DateInput}
                          clearIcon={null}
                          calendarIcon={null}
                          monthPlaceholder={'ММ'}
                          dayPlaceholder={'ДД'}
                          yearPlaceholder={'ГГГГ'}
                          onChange={setDateEnd}
                          value={dateEnd}
                          name="date_end"
                          type="text"
                          placeholder="ДД.ММ.ГГГГ"
                        />
                        <span className="authorization__error">
                          {errors.date_end &&
                            touched.date_end &&
                            errors.date_end}
                        </span>
                      </div>
                    </div>
                  </>
                  <>
                    {/* Цели и задачи */}
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="description">
                          Краткое описание инициативы
                        </label>
                        <Field
                          id="description"
                          name="description"
                          type="textarea"
                          component="textarea"
                          placeholder={'Краткое описание инициативы'}
                          onChange={handleChange}
                          value={values.description}
                        />
                        <span className="sendvote__error">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="actual">
                          Обоснование социальной значимости инициативы
                        </label>
                        <Field
                          id="actual"
                          name="actual"
                          type="textarea"
                          component="textarea"
                          placeholder={'Обоснование социальной значимости инициативы'}
                          onChange={handleChange}
                          value={values.actual}
                        />
                        <span className="sendvote__error">
                          {errors.actual &&
                            touched.actual &&
                            errors.actual}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="target">
                          Цель инициативы
                        </label>
                        <Field
                          id="target"
                          name="target"
                          type="textarea"
                          component="textarea"
                          placeholder={'Цель инициативы'}
                          onChange={handleChange}
                          value={values.target}
                        />
                        <span className="sendvote__error">
                          {errors.target &&
                            touched.target &&
                            errors.target}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="quantity_quality_result">
                          Ожидаемые количественные и качественные результаты от реализации инициативы
                        </label>
                        <Field
                          id="quantity_quality_result"
                          name="quantity_quality_result"
                          type="textarea"
                          component="textarea"
                          placeholder={'Ожидаемые количественные и качественные результаты от реализации инициативы'}
                          onChange={handleChange}
                          value={values.quantity_quality_result}
                        />
                        <span className="sendvote__error">
                          {errors.quantity_quality_result &&
                            touched.quantity_quality_result &&
                            errors.quantity_quality_result}
                        </span>
                      </div>
                    </div>
                  </>

                  <div className="sendvote__input-group double">
                    <div className="sendvote__input-block file">
                      <label htmlFor="file_calendar_plan">
                        Календарный план инициативы
                      </label>
                      <div className="file-text__wrap">
                        <input
                          className={
                            errors.file_calendar_plan && touched.file_calendar_plan
                              ? 'file error'
                              : 'file'
                          }
                          id="file_calendar_plan"
                          name="file_calendar_plan"
                          accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                          type="file"
                          onChange={(e) => {
                            if (
                              e.target.files[0].size /
                              1000 >
                              5000
                            ) {
                              return errorRegisterToast(
                                'Прикрепите файл размером меньше 5мб'
                              );
                            }
                            setFieldValue(
                              'file_calendar_plan',
                              e.currentTarget.files[0],
                            )
                            setFieldValue(
                              'file_calendar_plan_prev',
                              e.currentTarget.files[0].name,
                            )
                          }}
                        />
                        <span className="file-text__pseudo">
                          <img src={Attach} alt=""></img>
                        </span>
                        <span
                          className={
                            'file-text__value ' +
                            (values.file_calendar_plan ? 'active' : '')
                          }
                        >
                          {values.file_calendar_plan_prev
                            ? values.file_calendar_plan_prev
                            : 'Календарный план инициативы'}
                        </span>
                      </div>
                      <span className="sendvote__error">
                        {errors.file_calendar_plan &&
                          touched.file_calendar_plan &&
                          errors.file_calendar_plan}
                      </span>
                      <div className="sendvote__input-group download">
                      </div>
                    </div>
                    <div className="sendvote__input-block file">
                      <label htmlFor="file_smeta">
                        Смета инициативы
                      </label>
                      <div className="file-text__wrap">
                        <input
                          className={
                            errors.file_smeta && touched.file_smeta
                              ? 'file error'
                              : 'file'
                          }
                          id="file_smeta"
                          name="file_smeta"
                          accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                          type="file"
                          onChange={(e) => {
                            if (
                              e.target.files[0].size /
                              1000 >
                              5000
                            ) {
                              return errorRegisterToast(
                                'Прикрепите файл размером меньше 5мб'
                              );
                            }
                            setFieldValue(
                              'file_smeta',
                              e.currentTarget.files[0],
                            )
                            setFieldValue(
                              'file_smeta_prev',
                              e.currentTarget.files[0].name,
                            )
                          }}
                        />
                        <span className="file-text__pseudo">
                          <img src={Attach} alt=""></img>
                        </span>
                        <span
                          className={
                            'file-text__value ' +
                            (values.file_smeta ? 'active' : '')
                          }
                        >
                          {values.file_smeta_prev
                            ? values.file_smeta_prev
                            : 'Смета инициативы'}
                        </span>
                      </div>
                      <span className="sendvote__error">
                        {errors.file_smeta &&
                          touched.file_smeta &&
                          errors.file_smeta}
                      </span>
                      <div className="sendvote__input-group download">
                        <a href={Smeta} >Скачать шаблон сметы</a>
                      </div>
                    </div>
                  </div>
                  <hr />


                  <>
                    <div className="sendvote__subdescr">Ссылки инициативы</div>
                    <div className="sendvote__input-group double">
                      <div className="sendvote__input-block">
                        <label htmlFor="project_links_SMI">
                          Сайт инициативы
                        </label>
                        <Field
                          className={
                            errors.project_links_SMI &&
                              touched.project_links_SMI
                              ? 'input error'
                              : 'input'
                          }
                          id="project_links_SMI"
                          name="project_links_SMI"
                          type="text"
                          placeholder="Ссылка на СМИ"
                          onChange={handleChange}
                          value={values.project_links_SMI}
                        />
                        <span className="sendvote__error">
                          {errors.project_links_SMI &&
                            touched.project_links_SMI &&
                            errors.project_links_SMI}
                        </span>
                      </div>
                      <div className="sendvote__input-block">
                        <label htmlFor="project_links_SnOK">
                          Одноклассники
                        </label>
                        <Field
                          className={
                            errors.project_links_SnOK &&
                              touched.project_links_SnOK
                              ? 'input error'
                              : 'input'
                          }
                          id="project_links_SnOK"
                          name="project_links_SnOK"
                          type="text"
                          placeholder="Ссылка на Одноклассники"
                          onChange={handleChange}
                          value={values.project_links_SnOK}
                        />
                        <span className="sendvote__error">
                          {errors.project_links_SnOK &&
                            touched.project_links_SnOK &&
                            errors.project_links_SnOK}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group double">
                      <div className="sendvote__input-block">
                        <label htmlFor="project_links_SnVK">
                          VK
                        </label>
                        <Field
                          className={
                            errors.project_links_SnVK &&
                              touched.project_links_SnVK
                              ? 'input error'
                              : 'input'
                          }
                          id="project_links_SnVK"
                          name="project_links_SnVK"
                          type="text"
                          placeholder="Ссылка на VK"
                          onChange={handleChange}
                          value={values.project_links_SnVK}
                        />
                        <span className="sendvote__error">
                          {errors.project_links_SnVK &&
                            touched.project_links_SnVK &&
                            errors.project_links_SnVK}
                        </span>
                      </div>
                      <div className="sendvote__input-block">
                        <label htmlFor="project_links_Telegram">
                          Телеграм
                        </label>
                        <Field
                          className={
                            errors.project_links_Telegram &&
                              touched.project_links_Telegram
                              ? 'input error'
                              : 'input'
                          }
                          id="project_links_Telegram"
                          name="project_links_Telegram"
                          type="text"
                          placeholder="Ссылка на Телеграм"
                          onChange={handleChange}
                          value={values.project_links_Telegram}
                        />
                        <span className="sendvote__error">
                          {errors.project_links_Telegram &&
                            touched.project_links_Telegram &&
                            errors.project_links_Telegram}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block file w-full multiply">
                        <label htmlFor="files_multi">
                          Дополнительные материалы (письма поддержки, публикации
                          в СМИ, презентации и т.д. в pdf-формате)
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.files_multi && touched.files_multi
                                ? 'file error'
                                : 'file'
                            }
                            id="files_multi"
                            name="files_multi"
                            accept=".pdf"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              addAttachFiles(e.currentTarget.files)
                            }}
                            multiple
                          />
                          <div className="sendvote__loadico">
                            <img src={Loadimage} alt=""></img>
                          </div>
                          <span className="file-text__value"> </span>
                        </div>
                        {attachFiles.length > 0 && (
                          <div className="file-text__multiply">
                            {attachFiles.map((file) => (
                              <div
                                key={file.size + file.name}
                                className="file-text__multiply_item"
                              >
                                <div className="file-text__multiply_text">
                                  {file.name}
                                </div>
                                <img
                                  className="file-text__multiply_image"
                                  src={Cross}
                                  alt=""
                                  onClick={() => {
                                    deleteAttachFile(file.name)
                                  }}
                                ></img>
                              </div>
                            ))}
                          </div>
                        )}
                        <span className="sendvote__error">
                          {errors.files_multi &&
                            touched.files_multi &&
                            errors.files_multi}
                        </span>
                      </div>
                    </div>
                  </>

                  {values.is_individual === false && (<>
                    <div className="sendvote__subdescr">Информация об НКО</div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_name">Полное название НКО</label>
                        <Field
                          className={
                            errors.company_name && touched.company_name
                              ? 'input error'
                              : 'input'
                          }
                          id="company_name"
                          name="company_name"
                          type="text"
                          placeholder="Полное название НКО"
                          onChange={handleChange}
                          value={values.company_name}
                        />
                        <span className="sendvote__error">
                          {errors.company_name &&
                            touched.company_name &&
                            errors.company_name}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_short_name">Сокращенное  название НКО</label>
                        <Field
                          className={
                            errors.company_short_name && touched.company_short_name
                              ? 'input error'
                              : 'input'
                          }
                          id="company_short_name"
                          name="company_short_name"
                          type="text"
                          placeholder="Сокращенное  название НКО"
                          onChange={handleChange}
                          value={values.company_short_name}
                        />
                        <span className="sendvote__error">
                          {errors.company_short_name &&
                            touched.company_short_name &&
                            errors.company_short_name}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_ogrn">ОГРН</label>
                        <Field
                          className={
                            errors.company_ogrn && touched.company_ogrn
                              ? 'input error'
                              : 'input'
                          }
                          id="company_ogrn"
                          name="company_ogrn"
                          type="text"
                          placeholder="ОГРН"
                          onChange={handleChange}
                          value={values.company_ogrn}
                        />
                        <span className="sendvote__error">
                          {errors.company_ogrn &&
                            touched.company_ogrn &&
                            errors.company_ogrn}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_inn">ИНН</label>
                        <Field
                          className={
                            errors.company_inn && touched.company_inn
                              ? 'input error'
                              : 'input'
                          }
                          id="company_inn"
                          name="company_inn"
                          type="text"
                          placeholder="ИНН"
                          onChange={handleChange}
                          value={values.company_inn}
                        />
                        <span className="sendvote__error">
                          {errors.company_inn &&
                            touched.company_inn &&
                            errors.company_inn}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_fio">ФИО руководителя организации</label>
                        <Field
                          className={
                            errors.company_fio && touched.company_fio
                              ? 'input error'
                              : 'input'
                          }
                          id="company_fio"
                          name="company_fio"
                          type="text"
                          placeholder="ФИО руководителя организации"
                          onChange={handleChange}
                          value={values.company_fio}
                        />
                        <span className="sendvote__error">
                          {errors.company_fio &&
                            touched.company_fio &&
                            errors.company_fio}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_address_ur">
                          Юридический адрес НКО (регион, населенный пункт, улица, дом, квартира)*
                        </label>
                        <Field
                          id="company_address_ur"
                          name="company_address_ur"
                          type="textarea"
                          component="textarea"
                          placeholder={'Юридический адрес НКО (регион, населенный пункт, улица, дом, квартира)*'}
                          onChange={handleChange}
                          value={values.company_address_ur}
                        />
                        <span className="sendvote__error">
                          {errors.company_address_ur &&
                            touched.company_address_ur &&
                            errors.company_address_ur}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="company_address_fact">
                          Фактический адрес НКО (регион, населенный пункт, улица, дом, квартира)*
                        </label>
                        <Field
                          id="company_address_fact"
                          name="company_address_fact"
                          type="textarea"
                          component="textarea"
                          placeholder={'Фактический адрес НКО (регион, населенный пункт, улица, дом, квартира)*'}
                          onChange={handleChange}
                          value={values.company_address_fact}
                        />
                        <span className="sendvote__error">
                          {errors.company_address_fact &&
                            touched.company_address_fact &&
                            errors.company_address_fact}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__subdescr">Ссылки (НКО, руководителя организации)</div>
                    <div className="sendvote__input-group double">
                      <div className="sendvote__input-block">
                        <label htmlFor="company_links_Site">
                          Сайт НКО
                        </label>
                        <Field
                          className={
                            errors.company_links_Site &&
                              touched.company_links_Site
                              ? 'input error'
                              : 'input'
                          }
                          id="company_links_Site"
                          name="company_links_Site"
                          type="text"
                          placeholder="Ссылка на СМИ"
                          onChange={handleChange}
                          value={values.company_links_Site}
                        />
                        <span className="sendvote__error">
                          {errors.company_links_Site &&
                            touched.company_links_Site &&
                            errors.company_links_Site}
                        </span>
                      </div>
                      <div className="sendvote__input-block">
                        <label htmlFor="company_links_SnOK">
                          Одноклассники
                        </label>
                        <Field
                          className={
                            errors.company_links_SnOK &&
                              touched.company_links_SnOK
                              ? 'input error'
                              : 'input'
                          }
                          id="company_links_SnOK"
                          name="company_links_SnOK"
                          type="text"
                          placeholder="Ссылка на Одноклассники"
                          onChange={handleChange}
                          value={values.company_links_SnOK}
                        />
                        <span className="sendvote__error">
                          {errors.company_links_SnOK &&
                            touched.company_links_SnOK &&
                            errors.company_links_SnOK}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group double">
                      <div className="sendvote__input-block">
                        <label htmlFor="company_links_SnVK">
                          VK
                        </label>
                        <Field
                          className={
                            errors.company_links_SnVK &&
                              touched.company_links_SnVK
                              ? 'input error'
                              : 'input'
                          }
                          id="company_links_SnVK"
                          name="company_links_SnVK"
                          type="text"
                          placeholder="Ссылка на VK"
                          onChange={handleChange}
                          value={values.company_links_SnVK}
                        />
                        <span className="sendvote__error">
                          {errors.company_links_SnVK &&
                            touched.company_links_SnVK &&
                            errors.company_links_SnVK}
                        </span>
                      </div>
                      <div className="sendvote__input-block">
                        <label htmlFor="company_links_Telegram">
                          Телеграм
                        </label>
                        <Field
                          className={
                            errors.company_links_Telegram &&
                              touched.company_links_Telegram
                              ? 'input error'
                              : 'input'
                          }
                          id="company_links_Telegram"
                          name="company_links_Telegram"
                          type="text"
                          placeholder="Ссылка на Телеграм"
                          onChange={handleChange}
                          value={values.company_links_Telegram}
                        />
                        <span className="sendvote__error">
                          {errors.company_links_Telegram &&
                            touched.company_links_Telegram &&
                            errors.company_links_Telegram}
                        </span>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="other_links">
                          Другие ссылки
                        </label>
                        <Field
                          className={
                            errors.other_links &&
                              touched.other_links
                              ? 'input error'
                              : 'input'
                          }
                          id="other_links"
                          name="other_links"
                          type="text"
                          placeholder="Другие ссылки"
                          onChange={handleChange}
                          value={values.other_links}
                        />
                        <span className="sendvote__error">
                          {errors.other_links &&
                            touched.other_links &&
                            errors.other_links}
                        </span>
                      </div>
                    </div>

                    <div className="sendvote__subdescr">Документы</div>
                    <div className="sendvote__input-group double">
                      <div className="sendvote__input-block file">
                        <label htmlFor="ustavFiles">
                          <br />
                          <br />

                          Устав организации
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.company_file_ustavnie_multi &&
                                touched.company_file_ustavnie_multi
                                ? 'file error'
                                : 'file'
                            }
                            id="company_file_ustavnie_multi"
                            name="company_file_ustavnie_multi"
                            accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              addUstavFiles(e.currentTarget.files, (len) => {
                                setFieldValue(
                                  'company_file_ustavnie_hidden',
                                  len,
                                )
                              })
                            }}
                            multiple
                          />
                          <span className="file-text__pseudo">
                            <img src={Attach} alt=""></img>
                          </span>
                          <span
                            className={
                              'file-text__value ' +
                              (values.company_file_ustavnie_hidden ? 'active' : '')
                            }
                          >
                            {values.company_file_ustavnie_prev
                              ? values.company_file_ustavnie_prev
                              : 'Устав организации'}
                          </span>

                        </div>
                        {ustavFiles.length > 0 && (
                          <div className="file-text__multiply">
                            {ustavFiles.map((file) => (
                              <div
                                key={file.size + file.name}
                                className="file-text__multiply_item"
                              >
                                <div className="file-text__multiply_text">
                                  {file.name}
                                </div>
                                <img
                                  className="file-text__multiply_image"
                                  src={Cross}
                                  alt=""
                                  onClick={() => {
                                    deleteUstavFile(file.name)
                                  }}
                                ></img>
                              </div>
                            ))}
                          </div>
                        )}
                        <span className="sendvote__error">
                          {errors.company_file_ustavnie_multi &&
                            touched.company_file_ustavnie_multi &&
                            errors.company_file_ustavnie_multi}
                        </span>

                        <div className="sendvote__input-group download">
                        </div>
                      </div>
                      <div className="sendvote__input-block file">
                        <label htmlFor="company_file_appruve">
                          Электронную копию документа, подтверждающего полномочия лица на предоставление заявки от имени НКО
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.company_file_appruve && touched.company_file_appruve
                                ? 'file error'
                                : 'file'
                            }
                            id="company_file_appruve"
                            name="company_file_appruve"
                            accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              setFieldValue(
                                'company_file_appruve',
                                e.currentTarget.files[0],
                              )
                              setFieldValue(
                                'company_file_appruve_prev',
                                e.currentTarget.files[0].name,
                              )
                            }}
                          />
                          <span className="file-text__pseudo">
                            <img src={Attach} alt=""></img>
                          </span>
                          <span
                            className={
                              'file-text__value ' +
                              (values.company_file_appruve ? 'active' : '')
                            }
                          >
                            {values.company_file_appruve_prev
                              ? values.company_file_appruve_prev
                              : 'Доверенность'}
                          </span>
                        </div>
                        <span className="sendvote__error">
                          {errors.file_smeta &&
                            touched.file_smeta &&
                            errors.file_smeta}
                        </span>
                        <div className="sendvote__input-group download">
                        </div>
                      </div>
                    </div>
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block file w-full multiply">
                        <label htmlFor="files_multi">
                          Дополнительные материалы (письма поддержки, публикации
                          в СМИ, презентации и т.д. в pdf-формате)
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.files_multi && touched.files_multi
                                ? 'file error'
                                : 'file'
                            }
                            id="files_multi"
                            name="files_multi"
                            accept=".pdf"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              addAttachFiles(e.currentTarget.files)
                            }}
                            multiple
                          />
                          <div className="sendvote__loadico">
                            <img src={Loadimage} alt=""></img>
                          </div>
                          <span className="file-text__value"> </span>
                        </div>
                        {attachFiles.length > 0 && (
                          <div className="file-text__multiply">
                            {attachFiles.map((file) => (
                              <div
                                key={file.size + file.name}
                                className="file-text__multiply_item"
                              >
                                <div className="file-text__multiply_text">
                                  {file.name}
                                </div>
                                <img
                                  className="file-text__multiply_image"
                                  src={Cross}
                                  alt=""
                                  onClick={() => {
                                    deleteAttachFile(file.name)
                                  }}
                                ></img>
                              </div>
                            ))}
                          </div>
                        )}
                        <span className="sendvote__error">
                          {errors.files_multi &&
                            touched.files_multi &&
                            errors.files_multi}
                        </span>
                      </div>
                    </div>
                  </>)}

                  <hr />

                  {/* Согласия */}
                  <div className="sendvote__input-group">
                    <div className="sendvote__checkbox-block">
                      <label htmlFor="personal_data_confirmation">
                        <Field
                          id="personal_data_confirmation"
                          name="personal_data_confirmation"
                          type="checkbox"
                          onChange={handleChange}
                        />
                        <span>
                          Я даю{' '}
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://er.ru/upages/personal"
                          >
                            согласие на обработку{' '}
                          </a>
                          моих персональных данных в соответствии ФЗ №152-ФЗ «О
                          персональных данных» и на
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://er.ru/upages/privacy-policy"
                          >
                            {' '}
                            Политику конфиденциальности
                          </a>
                        </span>
                      </label>
                      <span className="sendvote__error">
                        {errors.personal_data_confirmation &&
                          touched.personal_data_confirmation &&
                          errors.personal_data_confirmation}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__checkbox-block">
                      <label htmlFor="agree">
                        <Field
                          id="agree"
                          name="agree"
                          type="checkbox"
                          onChange={handleChange}
                        />
                        <span>Согласие на участие в Конкурсе</span>
                      </label>
                      <span className="sendvote__error">
                        {errors.agree && touched.agree && errors.agree}
                      </span>
                    </div>
                  </div>

                  <div className="sendvote__input-button to-right">
                    <button
                      className="sendvote__btn-submit button"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading && <Loader />}
                      <span style={{ opacity: isLoading ? 0 : 1 }}>
                        Отправить
                      </span>
                    </button>
                  </div>
                  <ToastContainer />
                </Form>
              )}
            </>
          )
        }}
      </Formik>
      <ErrorModal
        isOpen={errorSend}
        setIsOpen={setErrorSend}
        errors={errorSend}
      />
    </>
  )
}
